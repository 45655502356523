import React from 'react';
import {
    Edit,
    SimpleForm,
    DisabledInput,
    DateInput,
    Toolbar,
    SaveButton,
    required,
    FormDataConsumer,
    SelectInput
} from 'react-admin';

import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton undoable={false} />
    </Toolbar>
));

const PurchaseEdit = props => (
    <Edit  {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <DisabledInput label="User ID" source="user_id" />
            <DisabledInput label="Product ID" source="product_id" />
            <DisabledInput label="Product Name" source="product_name" />
            <DisabledInput label="Transaction ID" source="transaction_id" />
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.product_id === 10001 || formData.product_id === 10002) {
                        return (
                            <>
                            <DisabledInput label="Expires" source="expiry_time"  {...rest} />
                            <SelectInput label="Add Expiry Time" source="expirytime" allowEmpty emptyValue="" choices={[
                                { id: 1, name: '1-Year' },
                            ]} validate={required()}  {...rest} />
                            </>
                        )
                    } else {
                        return <DateInput label="Expires" source="expiry_time" validate={required()} />
                    }
                }}
            </FormDataConsumer> */}
            <DateInput label="Expires" source="expiry_time" validate={required()} />
            <DisabledInput label="Purchased" source="purchase_time" />
            <DisabledInput label="Updated" source="updated_at" />
        </SimpleForm>
    </Edit>
);


export default PurchaseEdit;