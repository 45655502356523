import React, { Component } from 'react';
import {

    GET_LIST,
    Create,
    SimpleForm,
    DateInput,
    AutocompleteInput,
    required,
    SelectInput,
    ReferenceInput,
    FormDataConsumer
} from 'react-admin';
import dataProvider from '../../dataProvider'



class PurchaseCreate extends Component {

    state = {};

    componentDidMount() {
        const { location } = this.props;
        const { filter } = location;
        if (filter) {
            const { user_id } = JSON.parse(filter)
            const purchaseDefaultValue = { user: user_id };
            this.setState({ purchaseDefaultValue })
        }
        this.fetchData();
    }
    fetchData() {
        const date = new Date();
        this.setState({
            date
        });
    }



    render() {
        const { ...props } = this.props;

        const {
            date,
            purchaseDefaultValue
        } = this.state;

        return (
            <Create {...props} >
                <SimpleForm defaultValue={purchaseDefaultValue}>

                    <ReferenceInput
                        label="user"
                        source="user"
                        reference="allUser"
                        perPage={50}
                        filterToQuery={searchText => ({ email: searchText })}
                        validate={required()} >
                        <AutocompleteInput optionText="email" validate={required()} />
                    </ReferenceInput>
                    <ReferenceInput
                        label="product"
                        source="product"
                        reference="allProductAuto"
                        perPage={50}
                        filterToQuery={searchText => ({ name: searchText })}
                        fullWidth
                        validate={required()}>
                        <AutocompleteInput optionText="name" validate={required()} options={{ fullWidth: true }} />
                    </ReferenceInput>

                    {/*  <SelectInput source="expirytime" choices={[
                        { id: 1, name: '1-Year' },
                        { id: 2, name: 'Unlimited' },
                    ]} defaultValue={1} /> */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.product === 10001 || formData.product === 10002) {
                                return (
                                    <SelectInput source="expirytime" choices={[
                                        { id: 1, name: '1-Year' }
                                    ]} defaultValue={1} />
                                )
                            } else {
                                return <SelectInput source="expirytime" choices={[
                                    { id: 1, name: '1-Year' },
                                    { id: 2, name: 'Unlimited' },
                                ]} defaultValue={1} />
                            }
                        }}
                    </FormDataConsumer>

                    <DateInput label="purchasetime" source="purchase_time" defaultValue={date} options={{ format: 'DD/MM/YYYY, HH:mm:ss' }} validate={required()} />
                </SimpleForm>
            </Create>
        );
    }
}

export default PurchaseCreate;