import React from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    Responsive,
    TextField,
    NumberInput,
    TextInput,
    CardActions,
    RefreshButton,
    CreateButton
} from 'react-admin';

const PostActions = ({
    filters,
    filterValues,
    resource,
    showFilter,
    displayedFilters,
    basePath,
}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} label="Add"/>
        
    </CardActions>
);

const PurchaseFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="email" source="email" alwaysOn />
             <NumberInput label="user_id" source="user_id"/>
        </Filter>
    );
}

const PurchaseList = ({ classes, ...props }) => (
    <List
        {...props}
        filters={<PurchaseFilter />} bulkActionButtons={false} actions={<PostActions />} 
    >
        <Responsive
            medium={
                <Datagrid rowClick="edit">
                   <TextField label="User ID" source="user_id" sortable={false}/>
                   <TextField label="Email" source="email" sortable={false}/>
                   <TextField label="Product ID"source="product_id" sortable={false}/>
                    <TextField label="Product Name" source="product_name" sortable={false}/>
                    <DateField label="Purchased" source="purchase_time" showTime sortable={false}/>
                    <DateField label="Expires" source="expiry_time" showTime sortable={false}/>
                </Datagrid>
            }
        />
    </List>
);
export default PurchaseList;