import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField} from 'react-admin';

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Name" source="full_name" />
        <TextField label="Email" source="email" />
        <TextField label="Country" source="country" />
        <DateField label="Created" source="created_at" />
        <TextField lable="Migrate" source="migrate" />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;