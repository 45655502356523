import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import dataProvider from '../../dataProvider'
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import {  Button, showNotification, GET_ONE } from 'react-admin';

const styles = (theme) => ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
});

class InitPasswordConfim extends Component {

    state = {
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };
    handleInit = () => {
        const { record, showNotification } = this.props
        
        dataProvider(GET_ONE, 'resetPasswordByAdmin', { id: record.id })
            .then((data) => {
              this.setState({ showDialog: false });
                showNotification("New Password:" + data.data.id)
            })
            .catch((e) => {
              this.setState({ showDialog: false });
                showNotification('Error: ' + e.toString())
            })
      };

      render() {
        const { showDialog } = this.state;
        const { label = 'Reset Password' } = this.props;
        return (
          <Fragment>
            <Button onClick={this.handleClick} label={label} key="button">
            </Button>
            <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
              <DialogTitle>Are you sure you want to init Password?</DialogTitle>
              <DialogContent>
                <div>
                  Your actions will be logged.
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleInit} label={label}  key="button">
                </Button>
                <Button label="Cancel" onClick={this.handleCloseClick}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        );
      }

}

InitPasswordConfim.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
}
export default compose(
    connect(
      null,
      { showNotification,
        push, }
    ),
    withStyles(styles)
  )(InitPasswordConfim);

