import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, DateField,EditButton ,NumberInput} from 'react-admin'
import LinkToRelatedPurchase from './LinkToRelatedPurchase';
import { CardActions, RefreshButton } from 'react-admin';
const UserFilter = (props) => (
    <Filter {...props}>
      <NumberInput label="ID" source="id"/>
      <TextInput label="Email" source="email" alwaysOn/>
      <TextInput label="Name" source="full_name"/>
    </Filter>
  )

const PostActions = ({
    filters,
    filterValues,
    resource,
    showFilter,
    displayedFilters,

}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
       
    </CardActions>
);

const UserList = ({ classes, ...props }) => (
    <List {...props} filters={<UserFilter />}  bulkActionButtons={false} actions={<PostActions />} > 
       <Datagrid  rowClick="edit">
            <TextField source="id" sortable={false}/>
            <TextField source="full_name" sortable={false}/>
            <TextField  source="email" sortable={false}/>
            <TextField source="country" sortable={false}/>
            <DateField source="created_at" showTime sortable={false}/>
            {/* <EditButton /> */}
        </Datagrid>
    </List>
);

export default UserList;