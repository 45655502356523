import React from 'react';
import {
    Edit,
    SimpleForm,
    DisabledInput,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    CardActions,
    required
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import InitPasswordConfim from './InitPasswordConfim'
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const PostEditActions = ({ basePath, data, resource }) => {
    return (
        <CardActions>
            <Button color="primary"
                component={Link}
                to={{
                    pathname: '/purchase',
                    search: stringify({
                        page: 1,
                        perPage: 10,
                        filter: data && JSON.stringify({user_id:data.id}),
                    }),
                }} 
                 >Purchases</Button>
            <Button color="primary"
                component={Link}
                to={{
                    pathname: '/purchase/create',
                    filter: data && JSON.stringify({user_id:data.id}),
                }} >Add Purchase</Button>
            <InitPasswordConfim record={data} />
        </CardActions>
    );
}

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton undoable={false} />
        <DeleteButton undoable={false} />
    </Toolbar>
));

const UserEdit = props => (
    <Edit actions={<PostEditActions />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <DisabledInput label="Id" source="id" />
            <TextInput label="Name" source="full_name" validate={required()} />
            <DisabledInput label="Email" source="email" />
            <TextInput label="Country" source="country" />
            <DisabledInput label="Created" source="created_at" />
            <DisabledInput label="Updated" source="updated_at" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;