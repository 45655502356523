import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, DateField, CardActions, CreateButton, RefreshButton } from 'react-admin'
const PromoFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
  </Filter>
)
const PostActions = ({
  filters,
  filterValues,
  resource,
  showFilter,
  displayedFilters,
  basePath,
}) => (
    <CardActions>
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="Add" />
      
    </CardActions>
  );
const PromoList = ({ classes, ...props }) => (

  <List {...props} filters={<PromoFilter />} bulkActionButtons={false} actions={<PostActions />} >
    <Datagrid  rowClick='edit'>
      <TextField source="id" sortable={false} />
      <TextField source="code" sortable={false} />
      <TextField source="redeemed" sortable={false} />
      <TextField source="threshold" sortable={false} />
      <DateField source="created_at" showTime sortable={false} />
    </Datagrid>
  </List>
);

export default PromoList;