import React, { Fragment, Component } from 'react';
import {

    GET_LIST,
    Create,
    SimpleForm,
    TextInput,
    AutocompleteInput,
    NumberInput,
    required,
    FormDataConsumer,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput
} from 'react-admin';
import dataProvider from '../../dataProvider'
import { change } from 'redux-form'


class PromoCreate extends Component {
    state = {};

    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        this.fetchProducts();
    }

    async fetchProducts() {
        const { data } = await dataProvider(
            GET_LIST,
            'allProduct',
            {
                filter: {},
                sort: {},
                pagination: {},
            }
        );
        this.setState({
            data,
        });
    }

    render() {
        const { ...props } = this.props;
        const {
            data
        } = this.state;


        return (
            <Create {...props} >
                <SimpleForm form="myForm"  >
                    <TextInput source="code" validate={required()} />
                    <ArrayInput source="products" validate={required()} >
                        <SimpleFormIterator>
                            <FormDataConsumer>
                                {({ formData, dispatch, getSource,scopedFormData, ...rest }) =>
                                    <Fragment>
                                        {/* <AutocompleteInput source={getSource('product')} choices={data} onChange={(key, value) => {
                                            let selectData = data.find(e => e.id === value);
                                            if (typeof (selectData) != "undefined" || selectData != null) {
                                                dispatch(change('myForm', getSource('iapsku'), selectData.iapsku))
                                            }
                                        }}{...rest} validate={required()} /> */}

                                        <ReferenceInput
                                            source={getSource('product')}
                                            reference="allProductAuto"
                                            perPage={50}
                                            filterToQuery={searchText => ({ name: searchText })}
                                            fullWidth
                                            validate={required()} onChange={(key, value) => {
                                                 let selectData = data.find(e => e.id === value);
                                                if (selectData) {
                                                    dispatch(change('myForm', getSource('iapsku'), selectData.skupromo))
                                                } 
                                                
                                            }}{...rest} >
                                            <AutocompleteInput optionText="name" options={{ fullWidth: true }}  />
                                        </ReferenceInput>
                                        <TextInput source={getSource('iapsku')} label="IAPSKU Promo" validate={required()}  {...rest} />
                                    </Fragment>
                                }
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <NumberInput label="Theshold" source="UsageLimit" validate={required()} />
                </SimpleForm>
            </Create>
        );
    }
}

export default PromoCreate;