function apiBaseUrl() {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case 'dev':
        return 'https://development-api.itrackbites.com/api/admin';
      case 'staging':
        return 'https://api-staging.inavx.com/api/admin';
      case 'prod':
        return 'https://api.inavx.com/api/admin';
      default:
        return 'http://localhost:3000/api/admin';
    }
  }
  
  export const API_BASE_URL = apiBaseUrl();