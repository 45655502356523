import React from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin'
import Dashboard from './component/Dashboard'
import dataProvider from './dataProvider'
import authProvider from './provider/auth'
import users from './component/users'
import purchases from './component/purchases'
import promos from './component/promos'

const App = () => (
  <Admin title="iNavX Admin" authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider} >
   <Resource name="user" {...users}/>
   <Resource name="purchase" {...purchases} />
   <Resource name="promo" {...promos} />
   <Resource name="allUser"/>
   <Resource name="allProductAuto"/>
   
  </Admin>
)

export default App;
