import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { API_BASE_URL } from '../util/env';

export default async (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      
      const { username, password } = params;
      const request = new Request(API_BASE_URL + '/login', {
        method: 'POST',
        body: JSON.stringify({username, password}),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const userres = await response.json();
      if(userres.status!==200)
      {
        throw new Error(userres.message);
      }else{
        localStorage.setItem('token', userres.token);
      }
      
      return Promise.resolve();
    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      return Promise.resolve();
    case AUTH_ERROR:
      const { status } = params;
      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        return Promise.reject();
      }
      return Promise.resolve();
    case AUTH_CHECK:
      return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject();
    default:
      return Promise.reject('Unknown method');
  }
}