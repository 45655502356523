import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import { API_BASE_URL } from './util/env'

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('token')

  options.user = {
    authenticated: true,
    token: `Basic ${token}`
  }
  return fetchUtils.fetchJson(url, options)
}

export default simpleRestProvider(API_BASE_URL, httpClient)